'use client'

import { UnknownCompanyInfo } from '@/types'
import { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import CreateCompanyForm from './extension/pop-up/CreateCompanyForm'

const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export default function CreateCompanyModal({
  context,
  id,
  innerProps: { unknownCompanyInfo, companyOrgId, domain },
}: ContextModalProps<{
  unknownCompanyInfo: UnknownCompanyInfo | null
  companyOrgId: string
  domain: string
}>) {
  const onComplete = debounce(
    (success: boolean, title: string, message?: string) => {
      context.closeModal(id)
      notifications.show({
        title: title,
        message: message,
        color: success ? 'green' : 'red',
      })
    }
  )

  return (
    <CreateCompanyForm
      unknownCompanyInfo={unknownCompanyInfo}
      onComplete={onComplete}
      hostname={domain}
      standalone
      companyOrgId={companyOrgId}
    />
  )
}
